.train .image-gallery-description {
  font-size: 40px;
  text-align: center;
  background: none;
  left: 1%;
  right: 1%;
}


.image-gallery-description {
  font-size: 40px;
  text-align: center;
  background: none;
  left: 1%;
  right: 1%;
}
