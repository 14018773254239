   


@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);

:root {
  /* Base font size */
  /*font-size: 10px;   */
  
  /* Set neon color */
  --neon-text-color: #f40;
  --neon-border-color: #08f;
}

.body2 {
  font-family: 'Exo 2', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;  
  background: #000;
  background: #282c34;
  min-height: 100vh;
}

.neon-h1 {
    font-family: 'Exo 2', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;  
    background: #000;
    background: #282c34;
    /*min-height: 100vh;*/
    display: inline-block;


  font-size: 0.8rem;
  font-weight: 200;
  /*font-style: italic;*/
  color: #fff;
  /*padding: 4rem 6rem 5.5rem;*/
  padding: 1rem 1rem 1rem;
  border: 0.3rem solid #fff;
  border-radius: 1rem;
  text-transform: uppercase;
  animation: flicker 1.5s infinite alternate;     

  letter-spacing: 3px;
}

.neon-h1::-moz-selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

.neon-h1::selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

.neon-h1:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);
        
        box-shadow:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);        
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
        box-shadow: none;
    }    
}