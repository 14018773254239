.reaction .image-gallery-image {
  object-fit: cover;
}

.image-gallery-image img {
  object-fit: cover;
  width: 100%;
}

.reaction .image-gallery-description {
  font-size: 40px;
  text-align: center;
  background: none;
  left: 1%;
  right: 1%;
}


.image-gallery-description {
  font-size: 40px;
  text-align: center;
  background: none;
  left: 1%;
  right: 1%;
}
