



.preview {


}

.image-gallery-description {
  font-size: 40px;
  text-align: center;
  background: none;

  color: 'yellow';
}



