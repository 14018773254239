.rock {
  color: #fff; 
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135; 
  font: 50px 'ChunkFiveRegular';
}

.retro {
  color: #d7ceb2;
  text-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
  font: 50px 'BazarMedium';
  letter-spacing: 10px;
}


.rock-yellow {
  color: #fff; 
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135; 
  font: 50px 'ChunkFiveRegular';
  color: #FFFF00;
}

.rock-pink {
  color: #fff; 
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135; 
  font: 50px 'ChunkFiveRegular';
  color: rgb(255, 0, 149);
}

.deep {
  color: #e0dfdc;
  letter-spacing: .1em;
  text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9);
  color: #e0dfdc;
  font: 50px 'ChunkFiveRegular';
  /*background: #556677;*/
}

.retro-yellow {
  color: #d7ceb2;
  text-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
  font: 50px 'BazarMedium';
  letter-spacing: 10px;
  color: #FFFF00;
}


.fire {
  color: #fff;
  text-shadow: 0px -1px 4px white, 0px -2px 10px yellow, 0px -10px 20px #ff8000, 0px -18px 40px red; 
  font: 50px 'BlackJackRegular';
}

.nightInTheCity {
  color: #fff; 
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff2d95, 0 0 30px #ff2d95, 0 0 40px #ff2d95, 0 0 50px #ff2d95, 0 0 75px #ff2d95; 
  letter-spacing: 5px; 
  font: 50px 'MisoRegular';
}

.default { 
  font-size: 40px;
    font-weight: bolder;
    color: white;
    -webkit-text-fill-color: yellow;
    -webkit-text-stroke: 1px black;
    text-shadow: black 0px 0px 3px;
    font-family: Helvetica,sans-serif;
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace!important;
    font-weight: 600;
}



body {
  /*font-family: 'Russo One', monospace;*/
}

