.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  /*margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 3px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: white;
  margin-bottom: 3px;
}

.modal-dialog {
  /*max-width: 80%;*/
  max-width: 910px;
  max-width: 100%;
  margin-top: 0px;
}